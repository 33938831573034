import React from 'react';
import { Button, Heading, TableOfContents } from '../../components';
import { useTranslation } from 'react-i18next';
import Card from '../../components/Card/Card';

const DocumentationSDK = () => {
    const sections = [
        {
            id: 'heading',
            title: 'Heading'
        },
        {
            id: 'button',
            title: 'Button'
        },
        {
            id: 'card',
            title: 'Card'
        }
    ];

  return (
    <>
      <Heading align="left" transform="uppercase">Componentes</Heading>
      <TableOfContents sections={sections} />
      <Heading level={2} id='introduction' align="left" color="light-blue">Heading</Heading>
      <p>Componente que sirve para poner un titulo</p>
      <Heading align="center">Ejemplo</Heading>
      
      <Heading level={2} id='button' align="left" color="light-blue">Button</Heading>
        <p>Componente que sirve para poner un boton</p>
        <Button className="is-primary">Ejemplo</Button>

        <Heading level={2} id='card' align="left" color="light-blue">Card</Heading>
        <p>Componente que sirve para poner una tarjeta</p>
        <Card title="Ejemplo" image="/assets/navbar/01Logo_Header.svg" contents={[]} />
    </>
  );
};

export default DocumentationSDK;
DocumentationSDK.displayName = 'DocumentationSDK';

