import React from 'react';

interface HeadingProps {
  children: React.ReactNode;
  weight?: 'bold' | 'normal';
  color?: 'black' | 'light-blue' | 'green';
  transform?: 'uppercase' | 'lowercase' | 'capitalize';
  align?: 'center' | 'left' | 'right';
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  id?: string;
};

const getWeightClassName = (weight: string) => {
  switch (weight) {
    case 'bold':
      return 'has-text-weight-bold';
    case 'normal':
      return 'has-text-weight-normal';
    default:
      return 'has-text-weight-normal';
  }
};

const getColorClassName = (color: string) => {
  switch (color) {
    case 'black':
      return 'has-text-black-ter';
    case 'light-blue':
      return 'has-light-blue';
    case 'green':
      return 'has-text-success';
    default:
      return 'has-text-black-ter';
  }
};

const getTransformClassName = (transform?: string) => {
  switch (transform) {
    case 'uppercase':
      return 'is-uppercase';
    case 'lowercase':
      return 'is-lowercase';
    case 'capitalize':
      return 'is-capitalized';
    default:
      return '';
  }
};

const getAlignClassName = (align?: string) => {
  switch (align) {
    case 'center':
      return 'has-text-centered';
    case 'left':
      return 'has-text-left';
    case 'right':
      return 'has-text-right';
    default:
      return '';
  }
}

const Heading = ({ children, weight, color, transform, align, level, id}: HeadingProps) => {
  const weightClassName = getWeightClassName(weight || 'normal');
  const colorClassName = getColorClassName(color || 'black');
  const alignClassName = getAlignClassName(align || 'center');
  const transformClassName = getTransformClassName(transform);
  const HeadingTag = `h${level || 1}` as keyof JSX.IntrinsicElements;

  return (
    <HeadingTag id={id} className={`is-size-4 is-size-5-mobile ${weightClassName} ${colorClassName} ${transformClassName} ${alignClassName}`}>{children}</HeadingTag>
  );
};

export default Heading;
Heading.displayName = 'Heading';
