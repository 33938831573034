const en = {
  // Navbar
  'homepage.navbar.productos': 'Products',
  'homepage.navbar.laEmpresa': 'The Company',
  'homepage.navbar.tarifas': 'Rates',
  'homepage.navbar.desarrolladores': 'Developers',
  'homepage.navbar.login': 'Sign In',
  'homepage.navbar.register': 'Create Account',
  'homepage.navbar.productos.dropdown.title': 'Get the most out of your business',
  'homepage.navbar.productos.dropdown.text1': 'We understand that every business is unique.',
  'homepage.navbar.productos.dropdown.text2': "That's why our products are highly customized and scalable.",
  'homepage.navbar.productos.dropdown.text3': "Whether you're just starting out or have an established business, our tools adapt to your business.",
  'homepage.navbar.productos.dropdown.link1.text': 'Dashboard<0></0>all the suite in one place',
  'homepage.navbar.productos.dropdown.link1.description': 'We bring together the tools you need<0></0>to achieve your business goals.',
  'homepage.navbar.productos.dropdown.link2.text': 'Terminals (MPOS)',
  'homepage.navbar.productos.dropdown.link2.description': 'Learn about the models and features<0></0>of our product.',
  'homepage.navbar.productos.dropdown.link3.text': 'Ecommerce Solutions',
  'homepage.navbar.productos.dropdown.link3.description': 'An overview of our solution<0></0>and how you can implement it now.',
  'homepage.navbar.productos.dropdown.link4.text': 'Cards',
  'homepage.navbar.productos.dropdown.link4.description': 'A few clicks away from getting yours<0></0>learn how to do it.',

  // Banner
  'homepage.banner.title': 'We bring together all payment methods so you can sell more',
  'homepage.banner.description': "Your business needs to be close to your customer at the time of purchase, <0>we've developed a suite of products to accept payments</0> when you need it all on one platform.",
  'homepage.banner.button': 'Contact a Consultant',
  'homepage.banner.button.url': 'https://api.whatsapp.com/send?phone=523331416316&text=*%C2%A1Hola!*%20Me%20gustar%C3%ADa%20m%C3%A1s%20informaci%C3%B3n%20sobre%20como%20usar%20Espiralapp%20en%20mi%20negocio.',
  'homepage.banner.carousel.title': 'Espiralapp',
  'homepage.banner.carousel.subtitle': 'different solutions, <0></0>for your business to charge more',
  'homepage.banner.carousel.item1.title': 'Control Panel',
  'homepage.banner.carousel.item1.text1': 'Manage your account, users, and terminals from a single control panel.',
  'homepage.banner.carousel.item1.text2': 'Make the best decisions with comprehensive information on all transactions.',
  'homepage.banner.carousel.item2.title': 'Terminals (MPOS)',
  'homepage.banner.carousel.item2.text1': 'Accept card payments quickly and securely, wherever you are.',
  'homepage.banner.carousel.item2.text2': 'Our terminals are portable and easy to use at any time.',
  'homepage.banner.carousel.item3.title': 'Ecommerce Solutions',
  'homepage.banner.carousel.item3.text': 'Establish and manage your online presence with ease. Build your online store, our ecommerce solutions have you covered.',
  'homepage.banner.carousel.item4.title': 'Referenced Payments and Payment Links',
  'homepage.banner.carousel.item4.text': 'We want to provide you with the tools you need to simplify and enhance your financial operations. Our payment solutions are an essential part of that mission.',
  'homepage.banner.carousel.item5.title': 'Cards',
  'homepage.banner.carousel.item5.text1': 'Get access to service cards that suit your financial needs.',
  'homepage.banner.carousel.item5.text2': 'Enjoy flexible limits designed for your business.',
  'homepage.banner.carousel.item.seeMore.button': 'Learn more...',

  'homepage.banner.carousel.item1.title2': 'Control Panel',

  // Home Tabs
  'homepage.tabs.title': 'Give your business',
  'homepage.tabs.subtitle': 'the competitive edge it deserves',
  'homepage.tabs.item1.button': 'Terminals (MPOS)',
  'homepage.tabs.item1.title': 'Payments with:',
  'homepage.tabs.item1.item1': 'Chip',
  'homepage.tabs.item1.item2': 'Band',
  'homepage.tabs.item1.item3': 'NFC',
  'homepage.tabs.item1.item4': 'Express payment link',
  'homepage.tabs.item1.item5': 'Ticket printing',
  'homepage.tabs.item2.button': 'Ecommerce',
  'homepage.tabs.item2.text1': '<0>Our Ecommerce</0> is a user-friendly, secure, and reliable payment gateway; you can integrate payment methods in a couple of clicks.',
  'homepage.tabs.item2.text2': 'Always free and up-to-date plugins.',
  'homepage.tabs.item2.text3': 'create an account and discover how easy it is to start selling',
  'homepage.tabs.item3.button': 'Cards',
  'homepage.tabs.item3.text1': '<0>Our service cards</0> have been designed with you in mind, offering a powerful financial tool to boost your business.',
  'homepage.tabs.item3.text2': 'They are a flexible and convenient financing option designed specifically for business needs.',

  // Home community
  'homepage.community.title': 'Ready to join the community of satisfied customers?',
  'homepage.community.person1.text1': `"It's fast, secure, and our customers love the ease of payment. A perfect solution for our business!"`,
  'homepage.community.person1.text2': "",
  'homepage.community.person2.text1': '"Since we implemented the Espiralapp terminal in our store, our sales have increased significantly."',
  'homepage.community.person2.text2': `It's fast, secure, and our customers love the ease of payment. A perfect solution for our business!"`,
  'homepage.community.person3.text1': '"Thanks to Espiralapp eCommerce, our online store has experienced exponential growth."',
  'homepage.community.person3.text2': 'The integration was easy, and now we can reach customers across the country seamlessly."',
  'homepage.community.person4.text1': '"Our electronics store needed a reliable way to process credit card payments."',
  'homepage.community.person4.text2': 'Espiralapp is a must-have tool for any business!"',
  'homepage.community.person5.text1': '"The flexibility and convenience that Espiralapp provides have greatly improved our selling experience at events and fairs."',
  'homepage.community.person5.text2': 'Now we can accept payments wirelessly, creating more sales opportunities and satisfied customers."',

  // Home certifications and alliances
  'homepage.certifications.title': 'Certifications and Alliances',

  // Footer
  'footer.contactanos.header': 'Are you ready to get started?',
  'footer.contactanos.text1': 'Contact us or create a free account.',
  'footer.contactanos.text2': 'Start today',
  'footer.contactanos.button.contactUs': 'Contact Us',
  'footer.contactanos.button.createAccount': 'Create an Account',
  'footer.soluciones.header': 'Solutions',
  'footer.soluciones.item1': 'Terminals (MPOS)',
  'footer.soluciones.item2': 'Ecommerce',
  'footer.soluciones.item3': 'Dashboard',
  'footer.soluciones.item4': 'Cards',
  'footer.empresa.header': 'Company',
  'footer.empresa.item1': 'Rates',
  'footer.empresa.item2': 'Documentation (DEV)',
  'footer.clientes.header': 'Customer Service',
  'footer.clientes.item1': 'Contact',
  'footer.termsAndPrivacyPolicy': '<0>Terms and Conditions</0> & <2>Privacy Policies</2>',
  'footer.termsAndPrivacyPolicy.link': 'https://dash.espiralapp.com/en/privacyPolicies',



  // ----------------- Dashboard page -----------------
  'dashboard.banner.title': 'Dashboard',
  'dashboard.banner.subtitle': 'Make Informed Decisions',
  'dashboard.banner.text': "With access to real-time financial data and detailed analytics, you'll be better equipped to make informed decisions that drive your business success.",
  'dashboard.main.title': 'Why Our Dashboard is Essential for Your Business?',
  'dashboard.main.subtitle1': 'Facilitates Decision Making',
  'dashboard.main.text1': 'With easily accessible information, data updates in real time, our dashboard provides a comprehensive view of your business.',
  'dashboard.main.subtitle2': 'Total Control',
  'dashboard.main.text2': 'With all your information in one place, you have total control over your business, allowing you to make strategic changes effectively.',
  'dashboard.main.subtitle3': 'Discover how you can optimize your decision-making and simplify your business management',
  'dashboard.main.text3': 'We believe in the power of data to drive business success. Our dashboard is designed to put that power in your hands, providing you with the information you need to make more accurate and strategic decisions every day.',


  // ----------------- MPOS page -----------------
  'mpos.banner.title': 'MPOS Platform',
  'mpos.banner.subtitle': 'Sell more in person',
  'mpos.banner.text': "Every business has its own needs. <0>Our MPOS Terminals</0> are designed to adapt to your environment. <2>Whether it's a restaurant, bar, gas station, school, or an event</2> our terminals allow you to stay one step ahead.",
  'mpos.main.title': 'Why are our MPOS Terminals essential for your business?',
  'mpos.main.subtitle1': 'Speed and Convenience',
  'mpos.main.text1': 'With an intuitive user interface, our MPOS terminals streamline the payment process, providing your customers with a quick and hassle-free shopping experience.',
  'mpos.main.subtitle2': 'Real-Time Connection',
  'mpos.main.text2': 'Access transaction data in real time, allowing you to make informed decisions and better manage your products or track your sales. As well as filters to make partial cuts.',
  'mpos.main.newTerminals.title': 'Charge more with our point of sale terminals',
  'mpos.main.subtitle3': 'Mobility',
  'mpos.main.text3': 'Our MPOS terminals are portable and versatile, meaning you can accept payments anywhere, from a physical store to trade shows and more...',
  'mpos.main.subtitle4': 'Security',
  'mpos.main.text4': 'Your money every day in 24 hrs throughout the year, your customers can pay you with any card, in one installment or up to 18 months deferred.',


  // ----------------- Ecommerce page -----------------
  'ecommerce.banner.title': 'Ecommerce Platform',
  'ecommerce.banner.subtitle': 'Sell more in person',
  'ecommerce.banner.text': "Our tool has been optimized to streamline the payment process, <0>quick and agile integration, multiple payment methods</0> available. <2>Your customers will enjoy a quick and hassle-free shopping experience.</2>",
  'ecommerce.main.title': 'What Can You Expect from Our Ecommerce?',
  'ecommerce.main.subtitle1': 'Security',
  'ecommerce.main.text1': 'Transaction security is our priority. Our checkout is equipped with the latest security measures to protect your customers data and your company.',
  'ecommerce.main.subtitle2': 'Developers',
  'ecommerce.main.text2': 'To further facilitate integration with your website or platform, we offer comprehensive and simple developer documentation. This allows for a quick and seamless implementation of our payment solutions.',
  'ecommerce.main.carousel.title': 'Choose the solution that best fits your business model',
  'ecommerce.main.carousel.item1.title': 'Referenced Payments',
  'ecommerce.main.carousel.item1.text': 'Each transaction has a unique reference, making it easy to track and reconcile payments on your platform. Your customers make the payment at any of our affiliated merchants.',
  'ecommerce.main.carousel.item2.title': 'Payment Links',
  'ecommerce.main.carousel.item2.text': 'Create a payment link from your computer or mobile in just a few steps and turn it into a mail order, an order by sms, or a campaign on social networks.',
  'ecommerce.main.carousel.item3.title': 'API Documentation',
  'ecommerce.main.carousel.item3.text': 'Our APIs are designed with developers in mind and are easy to use. This means you can efficiently integrate our payment solution into your platform.',
  'ecommerce.main.subtitle3': 'Quality Technical Support',
  'ecommerce.main.text3': 'Our technical support team is ready to assist you at every step of the integration process, ensuring that everything runs smoothly.',
  'ecommerce.main.subtitle4': 'Simplify the Process',
  'ecommerce.main.text4': 'We believe that payment integration should not be complicated. Our ecommerce solution and APIs for developers are here to simplify the entire process, from initial setup to expanding your business.',


  // ----------------- Tarjetas page -----------------
  'cards.banner.title': 'Cards',
  'cards.banner.subtitle': 'Designed with your business in mind',
  'cards.banner.text1': "We understand your business's financial needs; our service cards offer a powerful financial tool to boost your business's specific needs, payroll, travel expenses, gasoline.",
  'cards.banner.text2': 'Take control of your business finances.',
  'cards.main.title': 'How Can Our Cards Benefit Your Business?',
  'cards.main.subtitle1': 'Financial Control',
  'cards.main.text1': "With an Espiralpay service card, you can effectively manage your company's expenses. Each transaction is recorded, making tracking and accounting easier.",
  'cards.main.subtitle2': 'Flexibility',
  'cards.main.text2': 'They provide you with instant access to additional funds when you need them. This is especially useful for covering unexpected expenses or seizing business opportunities.',
  'cards.main.subtitle3': 'Separate Your Personal and Business Finances',
  'cards.main.text3': "Using an Espiralpay service card allows you to maintain a clear separation between your personal and your company's finances, making it easier to manage your income from month to month.",

  // ----------------- Company page -----------------
  'company.banner.text': 'We are excited to introduce our suite of products, designed to drive your business success.',
  'company.banner.text2': 'In an increasingly digitalized world, efficient and secure financial management is essential.',
  'company.main.title': 'Focused on payment methods',
  'company.main.text1': 'With over 8 years of solid and successful experience, we pride ourselves on being a reference in the digital payments industry in Mexico. As a Mexican company, we have been committed to offering secure and reliable payment products tailored to the changing needs of our clients and the market.',
  'company.main.text2': 'We are a Banking Aggregator with a PCI-certified security level, ensuring that your transactions are protected with the highest security standards in the industry.',
  'company.main.text3': 'In addition, we strictly comply with all banking regulations imposed by the National Banking and Securities Commission (CNBV) and the Bank of Mexico (Banxico).',
  'company.main.text4': 'We understand the importance of trust in every transaction. Our commitment is to provide our clients with the peace of mind and security they deserve when trusting us with their financial operations.',
  'company.main.join.title': 'Join us and become part of our story',
  'company.main.join.text': 'We are always looking for strategic partners and professionals in the industry to join us in revolutionizing the digital payments landscape in Mexico. If you are interested in collaborating or working with us, we invite you to get in touch.',
  'company.main.contactUs.button': 'Contact Us',

  // ----------------- Rates page -----------------
  'rates.banner.text': '<0>Discover the rates for available payment methods</0> <2></2> With <4>espiralapp</4>, you will have a single integration',
  'rates.main.title': 'We develop payment solutions for businesses in Mexico',
  'rates.main.note': '*All costs are plus VAT',

  // ----------------- Developers page -----------------
  'documentation.nav.item1': 'Introduction',
  'documentation.nav.item2': 'Services',
  'documentation.nav.item3': 'Documentation',
  'documentation.nav.item4': 'Resources',
  'documentation.nav.item5': 'SDK',
  'documentation.tableOfContents.title': 'Content Table',

  // ----------------- Developers intro page -----------------
  'documentation.intro.title': 'Introduction',
  'documentation.intro.contentTable.title': 'Content Table',
  'documentation.intro.contentTable.item1': 'Service characteristics',
  'documentation.intro.contentTable.item2': 'How to integrate',
  'documentation.intro.text': 'Espiralapp is a payment gateway that allows you to integrate payment methods into your website or platform in a simple and secure way. We offer a variety of payment methods, including credit and debit cards, bank transfers, and cash payments. Our payment gateway is designed to be easy to use and integrate, allowing you to start accepting payments in a matter of minutes.',


  'placeholder.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis nisl vitae nisi fringilla tincidunt. Fusce id lacus quis leo consequat lacinia. Morbi sit amet augue quis magna aliquet ultrices. Quisque vitae felis ut dolor sagittis varius. Curabitur vitae nunc et leo malesuada faucibus. Phasellus quis lorem quis nisi sollicitudin tincidunt. Nunc quis turpis quis leo commodo ullamcorper. Mauris auctor, nisl ut sagittis sagittis, justo leo blandit nisi, sed ullamcorper lorem sapien id leo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec quis lacus quis eros vehicula luctus. Suspendisse potenti. Proin quis magna quis nisi mattis aliquam. In hac habitasse platea dictumst.'
  

}

export { en };
