const es = {
  // Navbar
  'homepage.navbar.productos': 'Productos',
  'homepage.navbar.laEmpresa': 'La Empresa',
  'homepage.navbar.tarifas': 'Tarifas',
  'homepage.navbar.desarrolladores': 'Desarrolladores',
  'homepage.navbar.login': 'Iniciar sesión',
  'homepage.navbar.register': 'Crear cuenta',
  'homepage.navbar.productos.dropdown.title': 'Saca el mayor provecho de tu negocio',
  'homepage.navbar.productos.dropdown.text1': 'Entendemos que cada negocio es único.',
  'homepage.navbar.productos.dropdown.text2': 'Por eso, nuestros productos son altamente personalizados y escalables.',
  'homepage.navbar.productos.dropdown.text3': 'Si acabas de comenzar o tiene una empresa consolidada, nuestras herramientas se adaptan a tu negocio.',
  'homepage.navbar.productos.dropdown.link1.text': 'Tablero de control<0></0>toda la suite en un lugar',
  'homepage.navbar.productos.dropdown.link1.description': 'Reunimos las herramientas que necesitas<0></0>para alcanzar tus metas comerciales.',
  'homepage.navbar.productos.dropdown.link2.text': 'Terminales (MPOS)',
  'homepage.navbar.productos.dropdown.link2.description': 'Conoce los modelos y las caracteristicas<0></0>de nuestro producto.',
  'homepage.navbar.productos.dropdown.link3.text': 'Soluciones ecommerce',
  'homepage.navbar.productos.dropdown.link3.description': 'Una vista general a nuestra solución<0></0>y como puedes implementarla ahora.',
  'homepage.navbar.productos.dropdown.link4.text': 'Tarjetas',
  'homepage.navbar.productos.dropdown.link4.description': 'A unos clicks de obtener la tuya<0></0>conoce como hacerlo.',

  // Banner
  'homepage.banner.title': 'Reunimos todos los métodos de pago para que vendas más',
  'homepage.banner.description': 'Tu negocio debe estar cerca de tu cliente al momento de la compra, <0>desarrollamos una suite de productos para aceptar pagos</0> cuando lo necesites en una sola plataforma.',
  'homepage.banner.button': 'Contacta a un asesor',
  'homepage.banner.button.url': 'https://api.whatsapp.com/send?phone=523331416316&text=*%C2%A1Hola!*%20Me%20gustar%C3%ADa%20m%C3%A1s%20informaci%C3%B3n%20sobre%20como%20usar%20Espiralapp%20en%20mi%20negocio.',
  'homepage.banner.carousel.title': 'Espiralapp',
  'homepage.banner.carousel.subtitle': 'diferentes soluciones, <0></0>para que tu negocio cobre más',
  'homepage.banner.carousel.item1.title': 'Panel de Control',
  'homepage.banner.carousel.item1.text1': 'Gestiona tu cuenta, usuarios y terminales desde un único panel de control.',
  'homepage.banner.carousel.item1.text2': 'Toma las mejores decisiones, con información completa de todas las operaciones.',
  'homepage.banner.carousel.item2.title': 'Terminales (MPOS)',
  'homepage.banner.carousel.item2.text1': 'Acepte pagos con tarjeta de forma rápida y segura, donde quiera que estés.',
  'homepage.banner.carousel.item2.text2': 'Nuestras terminales son portátiles y fáciles de usar en cualquier momento.',
  'homepage.banner.carousel.item3.title': 'Soluciones Ecommerce',
  'homepage.banner.carousel.item3.text': 'Establezca y administre su presencia en línea con facilidad. Desarrolle su tienda en línea, nuestras soluciones de ecommerce lo tienen cubierto.',
  'homepage.banner.carousel.item4.title': 'Pagos referenciados y links de cobro',
  'homepage.banner.carousel.item4.text': 'Queremos proporcionarte las herramientas que necesitas para simplificar y mejorar tus operaciones financieras. Nuestra soluciónes de cobro son una parte esencial de esa misión.',
  'homepage.banner.carousel.item5.title': 'Tarjetas',
  'homepage.banner.carousel.item5.text1': 'Obtenga acceso a tarjetas de servicio que se adaptan a sus necesidades financieras.',
  'homepage.banner.carousel.item5.text2': 'Disfrute de límites flexibles diseñados para su negocio.',
  'homepage.banner.carousel.item.seeMore.button': 'Saber más...',

  'homepage.banner.carousel.item1.title2': 'Panel de Control',

  // Home Tabs
  'homepage.tabs.title': 'Dale a tu negocio',
  'homepage.tabs.subtitle': 'la ventaja competitiva que merece',
  'homepage.tabs.item1.button': 'Terminales (MPOS)',
  'homepage.tabs.item1.title': 'Pagos con:',
  'homepage.tabs.item1.item1': 'Chip',
  'homepage.tabs.item1.item2': 'Banda',
  'homepage.tabs.item1.item3': 'NFC',
  'homepage.tabs.item1.item4': 'Link de cobro express',
  'homepage.tabs.item1.item5': 'Impresión de ticket',
  'homepage.tabs.item2.button': 'Ecommerce',
  'homepage.tabs.item2.text1': '<0>Nuestro Ecommerce</0> es una pasarela de pago amigable, segura y confiable, puedes integrar métodos de pago en un par de clics.',
  'homepage.tabs.item2.text2': 'Plugins siempre gratuitos y actualizados.',
  'homepage.tabs.item2.text3': 'crea una cuenta y descubre lo fácil que es empezar a vender',
  'homepage.tabs.item3.button': 'Tarjetas',
  'homepage.tabs.item3.text1': '<0>Nuestras tarjetas de servicios</0> han sido diseñadas pensando en ti, ofreciendo una poderosa herramienta financiera para impulsar tu empresa.',
  'homepage.tabs.item3.text2': 'Son una forma de financiamiento flexible y conveniente diseñadas específicamente para las necesidades comerciales.',

  // Home community
  'homepage.community.title': '¿Listo para unirte a la comunidad de clientes satisfechos?',
  'homepage.community.person1.text1': '"Desde que implementamos la terminal Espiralapp en nuestra agencia, nuestras ventas han aumentado significativamente."',
  'homepage.community.person1.text2': '',
  'homepage.community.person2.text1': '"Es rápido, seguro y nuestros clientes adoran la facilidad de pago. ¡Una solución perfecta para nuestro negocio!"',
  'homepage.community.person2.text2': '',
  'homepage.community.person3.text1': '"Gracias a Espiralapp eCommerce, nuestra tienda en línea ha experimentado un crecimiento exponencial."',
  'homepage.community.person3.text2': 'La integración fue sencilla y ahora podemos llegar a clientes en todo el país sin problemas."',
  'homepage.community.person4.text1': '"Nuestra tienda de productos electrónicos necesitaba una forma confiable de procesar pagos con tarjetas de crédito."',
  'homepage.community.person4.text2': '¡Espiralapp es una herramienta imprescindible para cualquier negocio!"',
  'homepage.community.person5.text1': '"La flexibilidad y comodidad que nos brinda Espiralapp ha mejorado enormemente nuestra experiencia de venta en eventos y ferias."',
  'homepage.community.person5.text2': 'Ahora podemos aceptar pagos de manera inalámbrica, lo que ha generado más oportunidades de ventas y clientes satisfechos."',

  // Home certifications and alliances
  'homepage.certifications.title': 'Certificaciones y Alianzas',

  // Footer
  'footer.contactanos.header': '¿Estás listo para empezar?',
  'footer.contactanos.text1': 'Contacta con nosotros o crea una cuenta gratis.',
  'footer.contactanos.text2': 'Comienza hoy mismo',
  'footer.contactanos.button.contactUs': 'Contáctanos',
  'footer.contactanos.button.createAccount': 'Crea una cuenta',
  'footer.soluciones.header': 'Soluciones',
  'footer.soluciones.item1': 'Terminales (MPOS)',
  'footer.soluciones.item2': 'Ecommerce',
  'footer.soluciones.item3': 'Tablero de control',
  'footer.soluciones.item4': 'Tarjetas',
  'footer.empresa.header': 'Empresa',
  'footer.empresa.item1': 'Tarifas',
  'footer.empresa.item2': 'Documentación (DEV)',
  'footer.clientes.header': 'Atención a clientes',
  'footer.clientes.item1': 'Contacto',
  'footer.termsAndPrivacyPolicy': '<0>Términos y condiciones</0> & <2>políticas de privacidad</2>',
  'footer.termsAndPrivacyPolicy.link': 'https://dash.espiralapp.com/es/privacyPolicies',



  // ----------------- Dashboard page -----------------
  'dashboard.banner.title': 'Tablero de control',
  'dashboard.banner.subtitle': 'Toma decisiones informadas',
  'dashboard.banner.text': 'Con acceso a datos financieros en tiempo real y análisis detallados, estarás mejor equipado para tomar decisiones informadas que impulsen el éxito de tu negocio.',
  'dashboard.main.title': '¿Por qué Nuestro Dashboard es Esencial para tu Negocio?',
  'dashboard.main.subtitle1': 'Facilita la Toma de Decisiones',
  'dashboard.main.text1': 'Con información fácilmente accesible, los datos se actualizan en tiempo real, nuestro dashboard proporciona una visión completa de tu negocio.',
  'dashboard.main.subtitle2': 'Control total',
  'dashboard.main.text2': 'Con toda tu información en un solo lugar, tienes un control total sobre tu negocio, permitiéndote hacer cambios estratégicos de manera efectiva.',
  'dashboard.main.subtitle3': 'Descubre cómo puedes optimizar tu toma de decisiones y simplificar la gestión de tu negocio',
  'dashboard.main.text3': 'Creemos en la capacidad de los datos para impulsar el éxito empresarial. Nuestro dashboard está diseñado para poner esa capacidad en tus manos, brindándote la información que necesitas para tomar decisiones diarias más acertadas y estratégicas.',


  // ----------------- MPOS page -----------------
  'mpos.banner.title': 'Plataforma MPOS',
  'mpos.banner.subtitle': 'Vende más presencialmente',
  'mpos.banner.text': 'Cada negocio tiene sus propias necesidades. <0>Nuestras Terminales MPOS</0> están diseñadas para adaptarse a tu entorno. <2>Ya sea un restaurante, un bar o gasolinera, escuela o un evento</2> nuestras terminales te permiten estar siempre un paso adelante.',
  'mpos.main.title': '¿Por qué nuestras Terminales MPOS son esenciales para tu negocio?',
  'mpos.main.subtitle1': 'Velocidad y Comodidad',
  'mpos.main.text1': 'Con una interfaz de usuario intuitiva, nuestras terminales MPOS agilizan el proceso de pago, brindando a tus clientes una experiencia de compra rápida y sin complicaciones.',
  'mpos.main.subtitle2': 'Conexión en Tiempo Real',
  'mpos.main.text2': 'Obtén acceso a datos de transacciones en tiempo real, lo que te permite tomar decisiones informadas y gestionar mejor tus productos o el seguimiento a la venta de tus servicios. Así como filtros para realizar cortes parciales.',
  'mpos.main.newTerminals.title': 'Cobra más con nuestras terminales puntos de venta',
  'mpos.main.subtitle3': 'Movilidad',
  'mpos.main.text3': 'Nuestras terminales MPOS son portátiles y versátiles, lo que significa que puedes aceptar pagos en cualquier lugar, desde una tienda física hasta eventos comerciales y más...',
  'mpos.main.subtitle4': 'Seguridad',
  'mpos.main.text4': 'Tu dinero todos los días en 24 hrs durante todo el año, tus clientes pueden pagarte con cualquier tarjeta, en una sola exhibición o hasta 18 meses diferidos.',


  // ----------------- Ecommerce page -----------------
  'ecommerce.banner.title': 'Plataforma Ecommerce',
  'ecommerce.banner.subtitle': 'Vende más presencialmente',
  'ecommerce.banner.text': 'Nuestra herramienta ha sido optimizada para minimizar el proceso de pago, <0>integración rápida y ágil, múltiples métodos de pago</0> disponibles. <2>Tus clientes disfrutarán de una experiencia de compra rápida y sin complicaciones.</2>',
  'ecommerce.main.title': '¿Qué Puedes Esperar de Nuestro Ecommerce?',
  'ecommerce.main.subtitle1': 'Seguridad',
  'ecommerce.main.text1': 'La seguridad de las transacciones es nuestra prioridad. Nuestro checkout está equipado con las últimas medidas de seguridad para proteger los datos de tus clientes y tu empresa.',
  'ecommerce.main.subtitle2': 'Desarrolladores',
  'ecommerce.main.text2': 'Para facilitar aún más la integración con tu sitio web o plataforma, ofrecemos una documentación completa y sencilla para desarrolladores. Esto permite una implementación rápida y sin complicaciones de nuestras soluciones de pago.',
  'ecommerce.main.carousel.title': 'Elige la solución que mejor se adapta a tu modelo de negocio',
  'ecommerce.main.carousel.item1.title': 'Pagos referenciados',
  'ecommerce.main.carousel.item1.text': 'Cada transacción tiene una referencia única, lo que facilita el seguimiento y la conciliación de pagos en tu plataforma. Tus clientes  realizan el pago en cualquiera de nuestros comercios afiliados.',
  'ecommerce.main.carousel.item2.title': 'Links de cobro',
  'ecommerce.main.carousel.item2.text': 'Crea un link de pago desde tu ordenador o móvil en muy pocos pasos y conviértelo en una venta por correo, en un pedido por sms o en una campaña en redes sociales.',
  'ecommerce.main.carousel.item3.title': 'Documentación APIs',
  'ecommerce.main.carousel.item3.text': 'Nuestras APIs están diseñadas pensando en los desarrolladores y son fáciles de usar. Esto significa que puedes integrar nuestra solución de pago de manera eficiente en tu plataforma.',
  'ecommerce.main.subtitle3': 'Soporte Técnico de Calidad',
  'ecommerce.main.text3': 'Nuestro equipo de soporte técnico está listo para ayudarte en cada paso del proceso de integración, asegurando que todo funcione sin problemas.',
  'ecommerce.main.subtitle4': 'Simplifica el proceso',
  'ecommerce.main.text4': 'Creemos que la integración de pagos no debería ser una complicación. Nuestra solución de ecommerce y nuestras APIs para desarrolladores están aquí para simplificar todo el proceso, desde la configuración inicial hasta la expansión de tu negocio.',


  // ----------------- Tarjetas page -----------------
  'cards.banner.title': 'Tarjetas',
  'cards.banner.subtitle': 'Diseñadas pensando en tu negocio',
  'cards.banner.text1': 'Entendemos que las necesidades financieras de tu negocio, nuestras tarjetas de servicio ofrecen una poderosa herramienta financiera para impulsar necesidades específicas de tu negocio, nómina, viáticos, gasolina.',
  'cards.banner.text2': 'Toma el control de tus finanzas comerciales.',
  'cards.main.title': '¿Comó podemos beneficiar a tu negocio?',
  'cards.main.subtitle1': 'Control Financiero',
  'cards.main.text1': 'Con una tarjeta de servicio espiralpay, puedes administrar de manera más efectiva los gastos de tu empresa. Cada transacción queda registrada, lo que facilita el seguimiento y la contabilidad.',
  'cards.main.subtitle2': 'Flexibilidad',
  'cards.main.text2': 'Te ofrecen acceso instantáneo a fondos adicionales cuando los necesitas. Esto es especialmente útil para cubrir gastos inesperados o aprovechar oportunidades comerciales.',
  'cards.main.subtitle3': 'Separa tus Finanzas Personales y Comerciales',
  'cards.main.text3': 'Utilizar una tarjeta de servicio espiralpay te permite mantener una clara separación entre tus finanzas personales y las de tu empresa, lo que facilita la gestión de tus ingresos mes con mes',

  // ----------------- Company page -----------------
  'company.banner.text': 'Estamos emocionados de presentarles nuestra suite de productos, diseñada para impulsar el éxito de tu negocio. ',
  'company.banner.text2': 'En un mundo cada vez más digitalizado, la gestión financiera eficiente y segura es esencial.',
  'company.main.title': 'Enfocados en medios de pago',
  'company.main.text1': 'Con más de 8 años de trayectoria sólida y exitosa, nos enorgullece ser un referente en la industria de pagos digitales en México. Como empresa mexicana, hemos estado comprometidos en ofrecer productos de cobro seguros y confiables, adaptados a las necesidades cambiantes de nuestros clientes y del mercado.',
  'company.main.text2': 'Somos un Agregador Bancario con un nivel de seguridad certificado PCI, lo que garantiza que tus transacciones estén protegidas con los más altos estándares de seguridad en la industria.',
  'company.main.text3': 'Además, cumplimos de manera estricta con todas las regulaciones bancarias impuestas por la Comisión Nacional Bancaria y de Valores (CNBV) y el Banco de México (Banxico).',
  'company.main.text4': 'Entendemos la importancia de la confianza en cada transacción. Nuestro compromiso es brindar a nuestros clientes la tranquilidad y seguridad que merecen al confiar en nosotros para sus operaciones financieras.',
  'company.main.join.title': 'Únete a miles de clientes satisfechos que han experimentado la diferencia de trabajar con un socio de confianza en pagos digitales',

  // ----------------- Rates page -----------------
  'rates.banner.text': '<0>Conoce las tarifas de los métodos de pagos disponibles</0> <2></2> Con <4>espiralapp</4>, tendrás una única integración',
  'rates.main.title': 'Desarrollamos soluciones de pago para comercios en México',
  'rates.main.note': '*Todos los costos son más IVA',


  // ----------------- Developers page -----------------
  'documentation.nav.item1': 'Inicio',
  'documentation.nav.item2': 'Servicios',
  'documentation.nav.item3': 'Documentación',
  'documentation.nav.item4': 'Recursos',
  'documentation.nav.item5': 'SDK',
  'documentation.tableOfContents.title': 'Tabla de contenido',


  // ----------------- Developers intro page -----------------
  'documentation.intro.title': 'Introducción',
  'documentation.intro.contentTable.title': 'Tabla de contenido',
  'documentation.intro.contentTable.item1': 'Caracteristicas del Servicio',
  'documentation.intro.contentTable.item2': 'Integraciones',
  'documentation.intro.text': 'Nuestras APIs están diseñadas pensando en los desarrolladores y son fáciles de usar. Esto significa que puedes integrar nuestra solución de pago de manera eficiente en tu plataforma.',


  'placeholder.text': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis nisl vitae nisi fringilla tincidunt. Fusce id lacus quis leo consequat lacinia. Morbi sit amet augue quis magna aliquet ultrices. Quisque vitae felis ut dolor sagittis varius. Curabitur vitae nunc et leo malesuada faucibus. Phasellus quis lorem quis nisi sollicitudin tincidunt. Nunc quis turpis quis leo commodo ullamcorper. Mauris auctor, nisl ut sagittis sagittis, justo leo blandit nisi, sed ullamcorper lorem sapien id leo. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec quis lacus quis eros vehicula luctus. Suspendisse potenti. Proin quis magna quis nisi mattis aliquam. In hac habitasse platea dictumst.'


}

export { es };
