import React from 'react';
import './TableOfContents.scss';
import { useTranslation } from 'react-i18next';

type Section = {
  id: string;
  title: string;
};

type TableOfContentsProps = {
  sections: Section[];
};

const TableOfContents = ({ sections }: TableOfContentsProps) => {
    const { t } = useTranslation();

  return (
    <nav className="table-of-contents px-5 py-4">
      <h2>{t('documentation.tableOfContents.title')}</h2>
      <ol className="ml-4">
        {sections.map((section) => (
          <li key={section.id}>
            <a className="has-text-grey-dark" href={`#${section.id}`}>{section.title}</a>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default TableOfContents;
TableOfContents.displayName = 'TableOfContents';
