
import React from 'react';

const DocumentationServices: React.FC = () => {
  return (
    <div>
        <h1>Servicios</h1>
    </div>
  );
};

export default DocumentationServices;
DocumentationServices.displayName = 'DocumentationServices';