import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

interface Props {
  // Define the props for your component here
}

const DocumentationMenu: React.FC<Props> = ({ /* Destructure your props here */ }) => {
    const { t } = useTranslation();

    const links = [
        {
            name: t('documentation.nav.item1'),
            url: '/documentacion/inicio',
        },
        {
            name: t('documentation.nav.item2'),
            url: '/documentacion/servicios',
        },
        {
            name: t('documentation.nav.item3'),
            url: '/documentacion/documentacion',
        },
        {
            name: t('documentation.nav.item4'),
            url: '/documentacion/recursos',
        },
        {
            name: t('documentation.nav.item5'),
            url: '/documentacion/sdk',
        },
        ];

  return (
    <aside role="navigation">
        <ul className="has-text-right">
            {links.map((link, index) => (
            <li key={link.name} className={index === 0 ? '' : 'mt-3'}>
                <NavLink to={link.url} className={(navData) => (navData.isActive? 'active-nav-item' : 'has-text-grey')}>
                {link.name}
                </NavLink>
            </li>
            ))}
        </ul>
        </aside>
  );
};

export default DocumentationMenu;
DocumentationMenu.displayName = 'DocumentationMenu';
