
import React, { useEffect } from 'react';
import Navbar from '../sections/navbar/Navbar';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@mui/material';
import DocumentationMenu from '../sections/documentation/DocumentationMenu';
import { Spacing } from '../components';
import TermsAndConditions from '../sections/footer/TermsAndConditions';

const Documentation = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
      setIsOpen((prevState) => !prevState)
  }
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);
  

  return (
    <>
      <Navbar />
      <section className="section">
        <div className="container">
          <div className="columns is-variable is-6 mt-6">
            <div className="column is-one-fifth">
              <div className="is-hidden-mobile">
                <DocumentationMenu />
              </div>
              <div className="is-hidden-tablet">
                <button onClick={toggleDrawer} className="button is-outlined ">Menu</button>
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    anchor='left'
                >
                  <Spacing padding={{ top: 3, left: 5, right: 5, bottom: 3 }} >
                    <DocumentationMenu />
                  </Spacing>
                </Drawer>
              </div>
            </div>
            <div className="column">
              <main className="docs-main-content">
                <Outlet />
              </main>
            </div>
          </div>
        </div>
      </section>
      <TermsAndConditions />
    </>
  );
};

export default Documentation;
