import React from 'react';
import { Heading, TableOfContents } from '../../components';
import { useTranslation } from 'react-i18next';

const DocumentationStart = () => {
  const { t } = useTranslation();
  const sections = [
    { id: 'introduction', title: t('documentation.intro.contentTable.item1') },
    { id: 'installation', title: t('documentation.intro.contentTable.item2') },
    { id: 'usage', title: 'Uso' },
    { id: 'contributing', title: 'Contribuir' },
    { id: 'license', title: 'Licencia' },
  ]

  return (
    <>
      <Heading align="left" transform="uppercase">Introduccion</Heading>
      <TableOfContents sections={sections} />
      <Heading level={2} id='introduction' align="left" color="green">{t('documentation.intro.contentTable.item1')}</Heading>
      <p>{t('documentation.intro.text')}</p>

      <p>{t('placeholder.text')}</p>

      <Heading level={2} id='installation' align="left" color="green">{t('documentation.intro.contentTable.item2')}</Heading>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>

      <Heading level={2} id='usage' align="left" color="green">{t('documentation.intro.contentTable.item2')}</Heading>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>

      <Heading level={2} id='contributing' align="left" color="green">{t('documentation.intro.contentTable.item2')}</Heading>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>

      <Heading level={2} id='license' align="left" color="green">{t('documentation.intro.contentTable.item2')}</Heading>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>
      <p>{t('placeholder.text')}</p>
    </>
  );
};

export default DocumentationStart;
DocumentationStart.displayName = 'DocumentationStart';

