import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const TermsAndConditions: React.FC = () => {
    const { t } = useTranslation();

  return (
    <footer className="footer has-text-white">
        <div className="container">
          <div className="columns content is-small is-vcentered">
            <div className="column">
              <div className="is-flex">
                <a href="https://www.facebook.com/MyEspiralApp" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/footer/26LogoFace-8.png" alt="" />
                </a>
                <a href="https://www.linkedin.com/company/myespiralapp" target="_blank" rel="noopener noreferrer">
                  <img src="/assets/footer/2LogoLinkedin-8.png" alt="" />
                </a>
              </div>
            </div>
            <div className="column has-text-centered">
              <Trans i18nKey="footer.termsAndPrivacyPolicy">
                <a className="has-text-white" href={t('footer.termsAndPrivacyPolicy.link')} target="_blank" rel="noopener noreferrer" /> <a className="has-text-white" href={t('footer.termsAndPrivacyPolicy.link')} target="_blank" rel="noopener noreferrer"></a>
              </Trans>
            </div>
            <div className="column has-text-right">
              © 2024 EspiralApp. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
  );
};

export default TermsAndConditions;
