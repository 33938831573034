import React from 'react';
import './App.scss';
import { useTranslation } from 'react-i18next';

function App() {
  return (
    <div className="App docs-main-content" />
  );
}

export default App;
